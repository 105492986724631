import {
  BusbudLG,
  BusbudMD,
  CreditCardLG,
  CreditCardMD,
  CrossLG,
  CrossMD,
  EmailLG,
  EmailMD,
  ExchangeLG,
  ExchangeMD,
  LuggageLG,
  LuggageMD,
  RefundLG,
  RefundMD,
  UserLG,
  UserMD,
} from "@busbud/design-system-components/dist/Icons";
import { ResponsiveColorProp } from "@busbud/design-system-components/dist/system/palette";

export type Shortcut = {
  formType: SHORTCUTS_KEYS;
  icon: {
    xs: typeof RefundMD;
    md: typeof RefundLG;
  };
  bgColor: ResponsiveColorProp;
  textColor: ResponsiveColorProp;
  iconColor: ResponsiveColorProp;
  categoryId?: number;
};

export type SHORTCUTS_KEYS = Exclude<Enum_Hcform_Formcomponent, "contact">;

export const SHORTCUTS: Shortcut[] = [
  {
    formType: "cancel_tickets",
    icon: {
      xs: RefundMD,
      md: RefundLG,
    },
    iconColor: "icon.notice.opaque",
    bgColor: "background.notice.opaque",
    textColor: "text.notice.opaque",
    categoryId: 115001240163,
  },
  {
    formType: "exchange_tickets",
    icon: {
      xs: ExchangeMD,
      md: ExchangeLG,
    },
    iconColor: "icon.notice.opaque",
    bgColor: "background.notice.opaque",
    textColor: "text.notice.opaque",
    categoryId: 115001240163,
  },
  {
    formType: "resend_confirmation",
    icon: {
      xs: EmailMD,
      md: EmailLG,
    },
    iconColor: "icon.notice.opaque",
    bgColor: "background.notice.opaque",
    textColor: "text.notice.opaque",
    categoryId: 115001240163,
  },
  {
    formType: "failed_booking",
    icon: {
      xs: CreditCardMD,
      md: CreditCardLG,
    },
    iconColor: "icon.informative.opaque",
    bgColor: "background.informative.opaque",
    textColor: "text.informative.opaque",
    categoryId: 115001267646,
  },
  {
    formType: "personal_data",
    icon: {
      xs: UserMD,
      md: UserLG,
    },
    iconColor: "icon.secondary.opaque",
    bgColor: "background.secondary.opaque",
    textColor: "text.primary.opaque",
  },
  {
    formType: "trip_not_available",
    icon: {
      xs: CrossMD,
      md: CrossLG,
    },
    iconColor: "icon.notice.opaque",
    bgColor: "background.notice.opaque",
    textColor: "text.notice.opaque",
    categoryId: 115001240163,
  },
  {
    formType: "find_boarding_information",
    icon: {
      xs: LuggageMD,
      md: LuggageLG,
    },
    iconColor: "icon.special.opaque",
    bgColor: "background.special.opaque",
    textColor: "text.special.opaque",
    categoryId: 360003778191,
  },
  {
    formType: "trip_feedback",
    icon: {
      xs: BusbudMD,
      md: BusbudLG,
    },
    iconColor: "icon.informative.opaque",
    bgColor: "background.informative.opaque",
    textColor: "text.informative.opaque",
    categoryId: 115001267646,
  },
];
