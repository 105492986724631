import { FC } from "react";

import Link from "next/link";
import { useRouter } from "next/router";

import { Card, Grid } from "@busbud/design-system-components";
import Heading from "@busbud/design-system-components/dist/Heading";
import Hidden from "@busbud/design-system-components/dist/Hidden";
import Label from "@busbud/design-system-components/dist/Label";
import Stack from "@busbud/design-system-components/dist/Stack";
import { makeStyles } from "@busbud/design-system-components/dist/styles";

import { FormLabels } from "@services/strapi";
import { tracking } from "@services/tracking";
import { SHORTCUTS } from "constants/shortcuts";

const useStyles = makeStyles(
  ({ busbud: { breakpoints, styles, spacing } }) => ({
    grid: {
      overflowY: "scroll",
      scrollbarWidth: "none",
      "-ms-overflow-style": "none",
      "&::-webkit-scrollbar": {
        display: "none",
      },
      flexWrap: "nowrap",
      marginTop: spacing(2),
      [breakpoints.up("sm")]: {
        overflowY: "unset",
        flexWrap: "wrap",
        marginTop: spacing(3),
      },
      [breakpoints.down("xs")]: {
        width: "100vw",
        marginLeft: `-${spacing(3)}`,
        padding: spacing(0, 3),
      },
    },
    card: {
      cursor: "pointer",
      height: 110,
      minWidth: 190,
      transition: "0.25s cubic-bezier(0.34, 1.56, 0.64, 1)",
      "&:hover": {
        transform: "translate3d(0, -4px, 12px)",
        boxShadow: styles.shadow.subtle.md,
      },
      [breakpoints.up("sm")]: {
        height: 140,
      },
    },
  })
);

type Props = {
  title?: string | null;
  formLabels: FormLabels;
  categoryId?: number;
  alignTitle?: "left" | "center";
};

export const Shortcuts: FC<Props> = ({
  formLabels,
  title,
  categoryId,
  alignTitle = "center",
}) => {
  const classes = useStyles();
  const { pathname, query, locale = "" } = useRouter();
  const filteredShortcuts = SHORTCUTS.filter(
    (shortcut) => !categoryId || shortcut.categoryId === categoryId
  );

  if (!filteredShortcuts.length) {
    return null;
  }

  return (
    <div>
      {title && (
        <Heading
          component="h2"
          size={{ xs: "sm", sm: "md" }}
          align={alignTitle}
          display="block"
        >
          {title}
        </Heading>
      )}
      <Grid
        container
        direction="row"
        justifyContent={{ xs: "flex-start", sm: "center" }}
        spacing={{ xs: 1, sm: 2 }}
        className={classes.grid}
      >
        {filteredShortcuts.map(
          ({ formType, bgColor, textColor, icon, iconColor }, index) => {
            const { xs: IconXS, md: IconMD } = icon;
            const label = formLabels[formType];
            return (
              <Grid item xs lg={categoryId ? undefined : 6} key={index}>
                <Link
                  shallow
                  replace
                  prefetch={false}
                  href={{
                    pathname: pathname,
                    query: { ...query, form: formType },
                  }}
                >
                  <Card
                    boxShadow="unset"
                    border={undefined}
                    bgColor={bgColor}
                    py={{ xs: 2, md: 2.5 }}
                    pl={2.5}
                    pr={{ xs: 3.5, md: 5 }}
                    className={classes.card}
                    onClick={() => tracking.clickHelpButton(formType, locale)}
                  >
                    <Stack
                      direction="column"
                      justifyContent="space-between"
                      height="100%"
                    >
                      <Hidden smUp>
                        <IconXS color={iconColor} />
                      </Hidden>
                      <Hidden xsDown>
                        <IconMD color={iconColor} />
                      </Hidden>
                      <Label
                        size={{ xs: "md", sm: "xl" }}
                        color={textColor}
                        fontWeight="bold"
                      >
                        {label}
                      </Label>
                    </Stack>
                  </Card>
                </Link>
              </Grid>
            );
          }
        )}
      </Grid>
    </div>
  );
};
