import { ListItem } from "@components/bulleted-list";
import {
  ZendeskArticle,
  ZendeskSearchResults,
} from "@services/zendesk/zendeskTypes";
import { CATEGORIES } from "constants/categories";

import type { FeaturedArticle } from "types/common";
import { urlBuilder } from "./urlBuilder";

export const getListItemFromArticle = (
  locale: string,
  {
    id,
    title,
    ...article
  }: ZendeskArticle | FeaturedArticle | ZendeskSearchResults,
  commonCategoryId?: number
): ListItem => {
  const categoryId =
    "categoryId" in article ? article.categoryId : commonCategoryId;

  const snippet = "snippet" in article ? article.snippet : undefined;
  return {
    id,
    title,
    path: urlBuilder.article(locale, id, title),
    color: categoryId ? CATEGORIES[categoryId]?.solidColor : undefined,
    snippet,
  };
};
