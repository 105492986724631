import { useEffect } from "react";

import Head from "next/head";

import Heading from "@busbud/design-system-components/dist/Heading";
import Stack from "@busbud/design-system-components/dist/Stack";

import { BulletedList } from "@components/bulleted-list";
import { Header } from "@components/header";
import { Layout } from "@components/layout";
import { SearchInput } from "@components/search-input";
import { Section } from "@components/section";
import { Shortcuts } from "@components/shortcuts";
import { CommonProps, getCommonProps } from "@services/common";
import { getTranslations } from "@services/strapi";
import { tracking } from "@services/tracking";
import { zendesk } from "@services/zendesk";
import { getListItemFromArticle } from "@utils/articles";
import { NextPageWithLayout } from "pages/_app";

import type { GetServerSideProps } from "next";
import type { FeaturedArticle } from "types/common";

interface Props {
  featuredArticles: FeaturedArticle[];
  locale: string | undefined;
  translations: HomepageTranslationsFragment & SearchInputTranslationsFragment;
  commonProps: CommonProps;
}

const Home: NextPageWithLayout<Props> = ({
  featuredArticles,
  locale,
  translations,
  commonProps,
}) => {
  useEffect(() => {
    tracking.homePage(`${locale}`);
  }, [locale]);

  return (
    <>
      <Head>
        <title>{translations.metadata?.title}</title>
        <meta
          name="description"
          content={translations.metadata?.description || ""}
        />
      </Head>
      <Header>
        <Heading component="h1" size={{ xs: "md", sm: "xl", md: "xxl" }}>
          {translations.searchTitle}
        </Heading>
        <SearchInput translations={translations} />
      </Header>
      <Section
        color="background.primary.opaque"
        curveDirection="reverse"
        gutters={2}
      >
        <Stack direction="column" spacing={{ xs: 5, sm: 8 }}>
          <Shortcuts
            formLabels={commonProps.formLabels}
            title={translations?.shortcutsTitle}
          />
          {locale && (
            <BulletedList
              dataCy="featured-articles"
              title={translations?.faqTitle}
              items={featuredArticles.map((article) =>
                getListItemFromArticle(locale, article)
              )}
              endIcon
              divider
            />
          )}
        </Stack>
      </Section>
    </>
  );
};

Home.getLayout = function getLayout(page) {
  return (
    <Layout {...page.props.commonProps} searchInFooter={false}>
      {page}
    </Layout>
  );
};

export default Home;

export const getServerSideProps: GetServerSideProps<Props> = async ({
  locale,
  res,
}) => {
  res.setHeader(
    "Cache-Control",
    "public, s-maxage=86400, stale-while-revalidate=60"
  );
  const [featuredArticles, translations = {}, commonProps] = await Promise.all([
    zendesk.getFeaturedArticles(`${locale}`),
    getTranslations(`${locale}`, "Homepage"),
    getCommonProps(`${locale}`),
  ]);

  return {
    props: {
      featuredArticles,
      locale,
      translations,
      commonProps,
    },
  };
};
